import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Grid from "styled-components-grid";
const Container = styled.div`
  display: flex;
  width: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const StandingsDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ecef;
`;

const Row = styled.div`
  display: flex;
  padding: 7px;
  border-bottom: ${(props) => (props.border ? "1px solid #293b52" : "")};
  border-top: ${(props) => (props.border_top ? "1px solid #293b52" : "")};
  :hover {
    background-color: #dadcde;
  }
`;

const Position = styled.div`
  display: flex;
  min-width: 40px;
  text-align: center;
  justify-content: center;
`;
const TeamName = styled.div`
  display: flex;
  min-width: 150px;
  margin-left: 0.5em;
`;
const Column = styled.div`
  display: flex;
  min-width: 50px;
  text-align: center;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  padding: 7px;
  background-color: #49617f;
  color: #fff;
`;
export default function Standings({ fixture }) {
  const [standings, setStandings] = useState();
  const { getStandings } = useContext(DataContext);

  useEffect(() => {
    let interval;
    if (fixture) {
      getStandings({
        season: fixture?.season?._id,
        date: fixture.date?.replace("Z", ""),
      }).then((data) => {
        setStandings(data);
      });
      interval = setInterval(() => {
        getStandings({
          season: fixture?.season?._id,
          date: fixture.date?.replace("Z", ""),
        }).then((data) => {
          setStandings(data);
        });
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [fixture]);

  return (
    <Container>
      <Grid style={{ width: "100%" }}>
        <Grid.Unit size={10 / 10}>
          <Panel>
            <PanelTitle title={"Standings"} />
            <PanelContent>
              <StandingsDiv>
                <Header>
                  <Position>#</Position>
                  <TeamName>Team</TeamName>
                  <Column>Pld</Column>
                  <Column>W</Column>
                  <Column>D</Column>
                  <Column>L</Column>
                  <Column>F</Column>
                  <Column>A</Column>
                  <Column>GD</Column>
                  <Column>Pts</Column>
                </Header>
                {standings?.teams.map((row) => {
                  let team = row;
                  let border = false;
                  let border_top = false;

                  return (
                    <Row border={border} border_top={border_top}>
                      <Position>{row.rank}</Position>
                      <TeamName>{team?.team?.name}</TeamName>
                      <Column>{row.matches_played}</Column>
                      <Column>{row.matches_won}</Column>
                      <Column>{row.matches_drawn}</Column>
                      <Column>{row.matches_lost}</Column>
                      <Column>{row.goals_for}</Column>
                      <Column>{row.goals_against}</Column>
                      <Column>{row.goal_difference}</Column>
                      <Column>{row.points}</Column>
                    </Row>
                  );
                })}
              </StandingsDiv>
            </PanelContent>
          </Panel>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}
