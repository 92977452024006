import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Grid from "styled-components-grid";
import Modal from "../UI/Modal/Modal";
import SavePanel from "../UI/Form/SavePanel";
import LineupEditor from "../Lineup/LineupEditor";
import { DataContext } from "../../contexts/DataContext";
import PlayerEditor from "../Modals/PlayerEditor";
import Hero from "../UI/Hero/Hero";
import HeroTeam from "../UI/Hero/HeroTeam";
import { SocketContext } from "../../contexts/SocketContext";
import Team from "../Team/Team";
import CornerBoxes from "../CornerBoxes/CornerBoxes";
import Standings from "../Standings/Standings";
import OfficialEditor from "../Modals/OfficialEditor";
import LivePage from "./LivePage";
import OPTAF28 from "./OPTAF28";
import Image from "../UI/Image";
import Input from "../UI/Form/Input";
import { Button } from "../../Theme/Hyper";
import SecondSpectrum from "../SecondSpectrum/SecondSpectrum";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ConfirmModal from "../Modals/ConfirmModal";
import CreateFixture from "../Modals/CreateFixture/CreateFixture";

const DELETE_GAME = gql`
  mutation DeleteGame($id: String!) {
    deleteGame(id: $id) {
      _id
    }
  }
`;

const GAME_BY_ID = gql`
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      opta_ID
      time
      description
      date
      coverage_level
      process_opta_data
      officials {
        official {
          first_name
          last_name
        }
        type
      }
      commentators {
        first_name
        last_name
      }
      stadium {
        _id
        opta_name
        name
      }
      competition {
        name
        _id
        competition_type
      }
      season {
        _id
        opta_ID
        name
      }
      home_team_kit
      away_team_kit
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            uefa_shirt_number
            player {
              _id
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          sub_order
          subbed
          captain
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            uefa_shirt_number
            player {
              _id
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          sub_order
          subbed
          captain
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            uefa_shirt_number
            player {
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            uefa_shirt_number
            player {
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        code
      }
      away_team {
        _id
        name
        code
      }
      live_data
      home_formation
      away_formation
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      opta_ID
      process_opta_data
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            uefa_shirt_number
            player {
              _id
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          captain
          subbed
          sub_order
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            uefa_shirt_number
            player {
              _id
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          captain
          subbed
          sub_order
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            uefa_shirt_number
            player {
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            uefa_shirt_number
            player {
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
            }
          }
          order
        }
      }
      live_data
      home_team_kit
      away_team_kit
      home_formation
      away_formation
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 52px;
  margin-bottom: 52px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 70px;
  margin-left: 200px;
`;

const DateDiv = styled.div`
  display: flex;
  margin-right: 1em;
`;

const SideBar = styled.div`
  width: 200px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;
  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;

const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  height: calc(100vh - 40px);
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;

const Score = styled.div`
  display: flex;
  font-size: 3em;

  justify-content: center;
`;

const TitleButtons = styled.div`
  display: flex;
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  justify-content: center;
`;
const KOTime = styled.div`
  display: flex;

  justify-content: center;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;
const Tab = styled.div`
  display: flex;
  padding: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 5px;
  background-color: ${(props) => (props.selected ? "#14ec8e" : "#e9ecef")};
  color: ${(props) => (props.selected ? "white" : "")};
  border-radius: 4px;
  cursor: pointer;
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function Fixture() {
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const [deleteGame, { data: deleteGameData }] = useMutation(DELETE_GAME);

  const [koDate, setKODate] = useState();
  const [koTime, setKOTime] = useState();
  const [fixture, setFixture] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [awayTeam, setAwayTeam] = useState();
  const [teams, setTeams] = useState();
  const [editLineup, setEditLineup] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedView, setSelectedView] = useState();
  const [selectedStatTab, setSelectedStatTab] = useState(0);
  const [editOfficial, setEditOfficial] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [commentators, setCommentators] = useState([]);
  const [showEdit, setShowEdit] = useState();

  let { url: path } = useRouteMatch();
  let location = useLocation();
  let history = useHistory();

  const { fixtureId } = useParams();
  const [savingState, setSavingState] = useState(0);

  const { updateLineup, updateGameCommentators } = useContext(DataContext);

  const { game, players, subscribeToGame, sendData } =
    useContext(SocketContext);

  useEffect(() => {
    if (fixtureId) {
      getGame({ variables: { id: fixtureId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureId]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore && fixtureId) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: fixtureId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.gameUpdated) return prev;
          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };
          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, fixtureId]);

  useEffect(() => {
    if (data) {
      setKODate(moment(data.gameById.date));
      setKOTime(moment(data.gameById.time, "HH:mmZ"));
      setFixture(data.gameById);
      setCommentators([...(data.gameById?.commentators || [])]);
    }
  }, [data]);

  async function saveLineup(newFixture) {
    await updateLineup({ id: newFixture._id, lineup: newFixture.lineup });
  }

  return (
    <>
      {/* <PopupBase></PopupBase> */}
      <Main>
        <SideBar>
          <Ul>
            <Li>
              <A
                selected={selectedView == "info"}
                selectedBG={selectedView == "info"}
                onClick={() => {
                  setSelectedView("info");
                }}
                to={`${path}`}
              >
                Game Info
              </A>
            </Li>
            <Li>
              <A
                selected={selectedView == "home"}
                selectedBG={selectedView == "home"}
                onClick={() => {
                  setSelectedView("home");
                }}
                to={`${path}/${fixture?.home_team?._id}/${fixture?.season?._id}`}
              >
                {fixture?.home_team?.name}
              </A>
            </Li>
            <Li>
              <A
                selected={selectedView == "away"}
                selectedBG={selectedView == "away"}
                onClick={() => {
                  setSelectedView("away");
                }}
                to={`${path}/${fixture?.away_team?._id}/${fixture?.season?._id}`}
              >
                {fixture?.away_team?.name}
              </A>
            </Li>
            {/* <Li>
              <A
                selected={selectedView == "boxes"}
                selectedBG={selectedView == "boxes"}
                onClick={() => {
                  setSelectedView("boxes");
                }}
                to={`${path}/boxes`}
              >
                Corner Boxes
              </A>
            </Li> */}
            {/* <Li>
              <A
                to={"season"}
                selected={selectedView == "season"}
                selectedBG={selectedView == "season"}
                onClick={() => {
                  setSelectedView("season");
                }}
              >
                Season Stats
              </A>
            </Li> */}
            <Li>
              <A
                to={`${path}/home_lineup`}
                selected={location.pathname.indexOf("home_lineup") > -1}
                selectedBG={location.pathname.indexOf("home_lineup") > -1}
                onClick={() => {
                  setSelectedView("home_lineup");
                }}
              >
                {fixture?.home_team?.name} Lineup
              </A>
            </Li>
            <Li>
              <A
                to={`${path}/away_lineup`}
                selected={location.pathname.indexOf("away_lineup") > -1}
                selectedBG={location.pathname.indexOf("away_lineup") > -1}
                onClick={() => {
                  setSelectedView("away_lineup");
                }}
              >
                {fixture?.away_team?.name} Lineup
              </A>
            </Li>
            <Li>
              <A
                to={`${path}/standings`}
                selected={location.pathname.indexOf("standings") > -1}
                selectedBG={location.pathname.indexOf("standings") > -1}
                onClick={() => {
                  setSelectedView("standings");
                }}
              >
                Standings
              </A>
            </Li>
            {/* <Li>
              <A
                to={`${path}/live`}
                selected={selectedView == "live"}
                selectedBG={selectedView == "live"}
                onClick={() => {
                  setSelectedView("live");
                }}
              >
                Live Stats
              </A>
            </Li> */}
            {/* <Li>
              <A
                to={`${path}/secondspectrum`}
                selected={selectedView == "secondspectrum"}
                selectedBG={selectedView == "secondspectrum"}
                onClick={() => {
                  setSelectedView("secondspectrum");
                }}
              >
                Second Spectrum
              </A>
            </Li> */}
            {/* <Li>
              <A
                to={"opta_f28"}
                selected={selectedView == "opta_f28"}
                selectedBG={selectedView == "opta_f28"}
                onClick={() => {
                  setSelectedView("opta_f28");
                }}
              >
                OPTA F28
              </A>
            </Li> */}
            {/* <Li>
              <A
                to={`${path}/standings`}
                selected={selectedView == "standings"}
                selectedBG={selectedView == "standings"}
                onClick={() => {
                  setSelectedView("standings");
                }}
              >
                Standings
              </A>
            </Li> */}
          </Ul>
        </SideBar>
        {/* <Title>New Fixture</Title> */}
        <Content>
          <Hero>
            <HeroTeam style={{ justifyContent: "flex-end" }}>
              {fixture?.home_team?.name}
            </HeroTeam>

            <Middle>
              <Score>
                {game?.home_scorers?.length || 0}-
                {game?.away_scorers?.length || 0}
              </Score>
              <KOTime>
                {koTime?.hours().toString().padStart(2, "0")}:
                {koTime?.minutes().toString().padStart(2, "0")}
              </KOTime>
            </Middle>

            <HeroTeam style={{ justifyContent: "flex-start" }}>
              {fixture?.away_team?.name}
            </HeroTeam>
          </Hero>
          <Switch>
            <Route path={`${path}/standings`}>
              <Standings fixture={fixture} />
            </Route>
            <Route path={`${path}/secondspectrum`}>
              <SecondSpectrum
                fixture={fixture}
                home_team={homeTeam}
                away_team={awayTeam}
              />
            </Route>
            <Route path={`${path}/home_lineup`}>
              <Panel>
                <PanelContent>
                  <LineupEditor
                    team={"home"}
                    fixture={fixture}
                    onChange={(lineup) => {
                      let newFixture = {
                        ...fixture,
                        lineup: { ...fixture.lineup },
                      };

                      if (!newFixture.lineup) {
                        newFixture.lineup = {};
                      }

                      newFixture.lineup.away =
                        newFixture?.lineup?.away?.map((p) => ({
                          ...p,
                          squad: p.squad._id,
                        })) || [];

                      newFixture.lineup.home =
                        lineup.map((p) => ({
                          squad: p.squad._id,
                          order: p.order,
                          yellow_card: p.yellow_card,
                          dbl_yellow_card: p.dbl_yellow_card,
                          red_card: p.red_card,
                          captain: p.captain ?? false,
                          subbed: false,
                        })) || [];
                      saveLineup(newFixture);
                    }}
                  />
                </PanelContent>
              </Panel>
            </Route>
            <Route path={`${path}/away_lineup`}>
              <Panel>
                <PanelContent>
                  <LineupEditor
                    team={"away"}
                    fixture={fixture}
                    onChange={(lineup) => {
                      let newFixture = {
                        ...fixture,
                        lineup: { ...fixture.lineup },
                      };

                      if (!newFixture.lineup) {
                        newFixture.lineup = {};
                      }
                      newFixture.lineup.home =
                        newFixture?.lineup?.home?.map((p) => ({
                          ...p,
                          squad: p.squad._id,
                        })) || [];

                      newFixture.lineup.away =
                        lineup.map((p) => ({
                          squad: p.squad._id,
                          order: p.order,
                          yellow_card: p.yellow_card,
                          dbl_yellow_card: p.dbl_yellow_card,
                          red_card: p.red_card,
                          captain: p.captain ?? false,
                          subbed: false,
                        })) || [];
                      saveLineup(newFixture);
                    }}
                  />
                </PanelContent>
              </Panel>
            </Route>

            {/* <Route path={`${path}/boxes`}>
              <CornerBoxes fixture={fixture} />
            </Route> */}
            <Route path={`${path}/season`}>Season</Route>
            <Route path={`${path}/live`}>
              <LivePage
                fixture={fixture}
                game={game}
                players={players}
                home_team={homeTeam}
                away_team={awayTeam}
              />
            </Route>
            <Route path={`${path}/opta_f28`}>
              <OPTAF28 fixture={fixture} game={game} />
            </Route>

            <Route path={`${path}/:teamId/:seasonId`}>
              <Team
                fixtureId={fixtureId}
                game={fixture}
                teams={teams}
                style={{ marginLeft: "0px", marginTop: "0px" }}
              />
            </Route>
            <Route path={`${path}`}>
              <Grid>
                <Grid.Unit size={1 / 4}>
                  <Panel>
                    <PanelTitle title="Fixture"></PanelTitle>
                    <PanelContent>
                      <Button
                        onClick={() => {
                          setShowEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        red
                        onClick={() => {
                          setConfirmDelete(true);
                        }}
                      >
                        Delete
                      </Button>
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 4}>
                  <Panel>
                    <PanelTitle title="Kick-Off"></PanelTitle>
                    <PanelContent>
                      <DateDiv>{koDate?.format("dddd Do  MMMM YYYY")}</DateDiv>
                      <DateDiv>
                        {koTime?.hours().toString().padStart(2, "0")}:
                        {koTime?.minutes().toString().padStart(2, "0")}
                      </DateDiv>
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 4}>
                  <Panel>
                    <PanelTitle title="Venue"></PanelTitle>
                    <PanelContent>
                      {fixture?.stadium?.name || fixture?.stadium?.opta_name}
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 4}>
                  <Panel>
                    <PanelTitle title="Stage"></PanelTitle>
                    <PanelContent>{fixture?.stage}</PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 3} style={{ marginTop: "10px" }}>
                  <Panel>
                    <PanelTitle title="Commentators"></PanelTitle>
                    <PanelContent>
                      <Table>
                        <thead>
                          <tr>
                            <TH>First Name</TH>
                            <TH>Last Name</TH>
                          </tr>
                        </thead>
                        <tbody>
                          {new Array(3).fill(1)?.map((_, index) => {
                            let comm = commentators?.[index];
                            return (
                              <tr key={index}>
                                <td>
                                  <Input
                                    value={comm?.first_name || ""}
                                    placeholder="First name"
                                    onChange={(e) => {
                                      let newComms = [...commentators];
                                      let newComm = {
                                        ...comm,
                                        first_name: e.target.value,
                                      };

                                      newComms[index] = newComm;
                                      setCommentators(newComms);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={comm?.last_name || ""}
                                    placeholder="Last name"
                                    onChange={(e) => {
                                      let newComms = [...commentators];
                                      let newComm = {
                                        ...comm,
                                        last_name: e.target.value,
                                      };
                                      newComms[index] = newComm;
                                      setCommentators(newComms);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </PanelContent>
                    <SavePanel
                      state={savingState}
                      onCancel={() => {
                        let comms = [...fixture?.commentators];

                        setCommentators(comms);
                      }}
                      onSave={() => {
                        setSavingState("SAVING");
                        updateGameCommentators({
                          id: fixture._id,
                          commentators: commentators,
                        }).then(() => {
                          setSavingState("SAVED");
                          setTimeout(() => {
                            setSavingState(0);
                          }, 1000);
                        });
                      }}
                      style={{ marginLeft: "auto" }}
                    />
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 3} style={{ marginTop: "10px" }}>
                  <Panel>
                    <PanelTitle title="Officials"></PanelTitle>
                    <PanelContent>
                      <Table>
                        <thead>
                          <tr>
                            <TH>First Name</TH>
                            <TH>Last Name</TH>
                          </tr>
                        </thead>
                        <tbody>
                          {fixture?.officials?.map((official, index) => {
                            return (
                              <tr key={index}>
                                <td>{official?.official?.first_name}</td>
                                <td>{official?.official?.last_name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 3}></Grid.Unit>
                <Grid.Unit size={1 / 4} style={{ marginTop: "10px" }}>
                  <Panel>
                    <PanelTitle title="OPTA ID"></PanelTitle>
                    <PanelContent>{fixture?.opta_ID}</PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 4} style={{ marginTop: "10px" }}>
                  <Panel>
                    <PanelTitle title="PROCESS OPTA DATA"></PanelTitle>
                    <PanelContent>
                      {fixture?.process_opta_data ? "Yes" : "No"}
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
              </Grid>
            </Route>
          </Switch>
        </Content>
        <Modal
          show={editLineup}
          onCancel={() => {
            setEditLineup(false);
          }}
        >
          {editLineup && (
            <Panel>
              <PanelTitle title={"Lineup Editor"}></PanelTitle>
              <PanelContent>
                <LineupEditor
                  team={editLineup}
                  fixture={fixture}
                  game={game}
                  onChange={(lineup) => {
                    fixture.live_data[`${editLineup}_team_lineup`] = lineup;
                  }}
                />
              </PanelContent>
              <SavePanel
                style={{ marginLeft: "auto" }}
                onSave={() => {
                  saveLineup();
                }}
                onCancel={() => {
                  setEditLineup(false);
                }}
              />
            </Panel>
          )}
        </Modal>
        <PlayerEditor
          game={game}
          team={selectedTeam}
          player={selectedPlayer}
          selectPlayer={(p) => {
            setSelectedPlayer(p);
          }}
          onCancel={() => {
            setSelectedPlayer();
          }}
          onSave={() => {
            setSelectedPlayer();
          }}
        />
        <ConfirmModal
          title="Are you sure you want to delete this game?"
          show={confirmDelete}
          onConfirm={() => {
            deleteGame({ variables: { id: fixture._id } });
            setConfirmDelete(false);
            history.push("/");
          }}
          onCancel={() => {
            setConfirmDelete(false);
          }}
        ></ConfirmModal>
        <CreateFixture
          edit={true}
          fixture={fixture}
          show={showEdit}
          onCancel={() => {
            setShowEdit(false);
          }}
          onSaved={() => {
            //getData();
            setShowEdit(false);
          }}
        />
      </Main>
    </>
  );
}
